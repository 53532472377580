<template>
  <div class="full-width">
    <!-- subUsersActive:{{subUsersActive}} -->
    <div ref="userrow" class="d-flex card-inner mx-0 px-0 justify-start" v-if="sortedUsers">
      <div v-for="(user, index) in sortedUsers" :key="user._id" class="col-card mx-0 pa-0">
        <div
          @click="subUsersActive = user.subUsers && subUsersActive != user._id ? user._id : null"
          class="mx-auto card-wrapper"
          :class="[
            teamAdminUser && sortedUsers.length > 1
              ? index == 0
                ? 'border-top-right'
                : index == sortedUsers.length - 1
                ? 'border-top-left'
                : 'border-top'
              : '',
          ]"
        >
          <!-- :class="[  teamAdminUser && sortedUsers.length>1? index==0?'border-top-right':index==sortedUsers.length-1?'border-top-left':'border-top':'']" -->
          <div class="c-card mx-3 pa-3" :class="[subUsersActive == user._id ? 'active' : '']">
            <div v-if="user.reportsToId" class="line-top"></div>
            <!-- <div v-if="subUsersActive != user._id" class="open-ind"><i class="fas fa-plus"></i></div> -->
            <div v-if="subUsersActive == user._id" class="closed-ind">
              <i class="fas fa-minus"></i>
            </div>
            <v-row>
              <v-col cols="9" class="py-0 name-role">
                <div>{{ user.firstName }} {{ user.lastName }}</div>
                <div class="sub-title">{{ user.roleTitle }}</div>
                <div class="sub-title">{{ user.teamName }}</div>
              </v-col>
              <v-col cols="3" class="px-0">
                {{ user.status }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="my-2 py-0">
                <div class="row justify-space-between">
                  <div>
                    <v-btn
                      icon
                      @click.stop="editUser(user)"
                      v-if="
                        auth.authUser.isAdmin ||
                        auth.authUser.isCompanyAdmin ||
                        auth.authUser.canAddUsers
                      "
                    >
                      <!-- <i class="fas fa-ellipsis-v"></i> -->
                      <i class="far fa-edit"></i>
                    </v-btn>
                    <!-- <v-btn icon>
                    <i class="far fa-envelope mx-3"></i>
                    </v-btn>-->
                  </div>
                  <div v-if="user.isTrainingUser">
                    <div>
                      <span class="primary--text">{{
                        getProgress(user._id, progressReport).sectionOrder
                      }}</span>
                      / {{ sections.length - 1 }}
                    </div>
                    <div class="sub-title">
                      {{ getProgress(user._id, progressReport).progressStatus }}
                    </div>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" class="mt-2 py-0" v-if="user.isTrainingUser">
                <v-progress-linear
                  color="primary"
                  :value="getProgress(user._id, progressReport).percentComplete"
                ></v-progress-linear>
              </v-col>
              <!-- <v-col cols="12">
                <div class="text-center">
                  <v-badge overlap  :content="user.subUsers?user.subUsers.length:'0'">
                    <v-btn icon>
                      <i class="fas fa-users"></i>
                    </v-btn>
                  </v-badge>
                </div>
              </v-col>-->
            </v-row>
            <div
              v-if="
                (auth.authUser.isAdmin ||
                  auth.authUser.isCompanyAdmin ||
                  auth.authUser.canAddUsers) &&
                !user.reportsToId &&
                usersPlan._id != '1'
              "
              class="add-btn add-top"
              @click.stop="add(null, user._id)"
            >
              <i class="fas fa-plus"></i>
            </div>
            <div
              v-if="
                (auth.authUser.isAdmin ||
                  auth.authUser.isCompanyAdmin ||
                  auth.authUser.canAddUsers) &&
                usersPlan._id != '1'
              "
              class="add-btn add-right"
              @click.stop="add(user.reportsToId)"
            >
              <i class="fas fa-plus"></i>
            </div>
            <div
              v-if="
                (auth.authUser.isAdmin ||
                  auth.authUser.isCompanyAdmin ||
                  auth.authUser.canAddUsers) &&
                usersPlan._id != '1'
              "
              class="add-btn add-bottom"
              @click.stop="add(user._id)"
            >
              <i class="fas fa-plus"></i>
            </div>
            <div
              v-if="
                (auth.authUser.isAdmin ||
                  auth.authUser.isCompanyAdmin ||
                  auth.authUser.canAddUsers) &&
                usersPlan._id != '1'
              "
              class="add-btn add-left"
              @click.stop="add(user.reportsToId)"
            >
              <i class="fas fa-plus"></i>
            </div>

            <div class="show-more text-center" v-if="user.subUsers">
              <v-badge overlap :content="user.subUsers ? user.subUsers.length : '0'">
                <v-btn icon>
                  <i class="fas fa-users"></i>
                </v-btn>
              </v-badge>
            </div>
            <div v-if="user.subUsers" class="line-bot"></div>
          </div>
        </div>

        <!-- {{user.subUsers}} -->
        <div class="sub-card-wrapper" v-if="user.subUsers && subUsersActive == user._id">
          <UserRow
            :usersPlan="usersPlan"
            @add="addSubUser"
            :users="users"
            :progressReport="progressReport"
            :teamAdminUser="user._id"
          ></UserRow>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import UserRow from "@/components/UserRow.vue";

export default {
  name: "UserRow",
  computed: mapState(["auth"]),
  components: {
    UserRow,
  },
  props: {
    users: Array,
    teamAdminUser: String,
    progressReport: Array,
    usersPlan: Object,
  },
  data() {
    return {
      sortedUsers: null,
      subUsersActive: null,
      sections: [
        { lessonCount: 3 },
        { lessonCount: 10 },
        { lessonCount: 7 },
        { lessonCount: 10 },
        { lessonCount: 8 },
        { lessonCount: 10 },
        { lessonCount: 12 },
        { lessonCount: 1 },
        { lessonCount: 0 },
      ],
    };
  },
  mounted() {
    this.sortUsers(this.users, this.teamAdminUser);
  },
  watch: {
    users(newVal, oldVal) {
      this.sortUsers(this.users, this.teamAdminUser);
    },
    // progressReport(newVal, oldVal) {
    //   this.users.forEach((u) => {
    //     const p = getProgress(u._id, progressReport);
    //     u.progress = p;
    //   });
    // },
  },
  methods: {
    getProgress(userId, progressReport) {
      if (progressReport) {
        const progressUser = progressReport.find((x) => x._id == userId);
        if (progressUser && progressUser.progress) {
          let userProgress = Object.assign({}, progressUser.progress);
          userProgress.percentComplete =
            (userProgress.sectionOrder / (this.sections.length - 1)) * 100;
          userProgress.progressStatus = this.getProgressStatus({
            progress: userProgress,
          });

          return userProgress;
        } else {
          let userProgress = {
            complete: false,
            percentComplete: 0,
            lessonOrder: 0,
            sectionOrder: 0,
          };
          userProgress.progressStatus = this.getProgressStatus({
            progress: userProgress,
          });
          return userProgress;
        }
      } else {
        return 0;
      }
    },
    getProgressStatus(user) {
      return user.progress && user.progress.percentComplete > 0
        ? user.progress.complete
          ? "Completed"
          : "In Progress"
        : "Not Started";
    },
    editUser(user) {
      this.$emit("add", user);
    },
    add(reportsToId, selectedUserId) {
      this.$emit("add", {
        reportsToId: reportsToId,
        selectedUserId: selectedUserId,
        updateSelectedUserReport: true,
      });
    },
    addSubUser(data) {
      this.$emit("add", data);
    },
    sortUsers(users, reportsToId) {
      if (users) {
        const self = this;
        let newUsers;
        // let teamAdminUser;
        if (
          !reportsToId &&
          (this.auth.authUser.isAdmin == true ||
            this.auth.authUser.isTeamAdmin == true ||
            this.auth.authUser.isCompanyAdmin == true)
        ) {
          newUsers = users.filter((x) => !x.reportsToId);
        } else if (!reportsToId) {
          newUsers = users.filter((x) => x._id == this.auth.authUser._id);
          // newUsers = users.filter(
          //   x => x._id == this.auth.authUser._id || x.reportsToId == this.auth.authUser._id
          // );
        } else {
          // teamAdminUser = this.users.find(x => x._id == reportsToId);
          newUsers = users.filter((x) => x.reportsToId == reportsToId);
        }

        console.log("newUsers", newUsers);

        let me = false;
        newUsers.forEach((newUser) => {
          const subUsers = users.filter((x) => x.reportsToId == newUser._id);
          me = me || newUser._id == this.auth.authUser._id;
          console.log("me", me);
          if (subUsers && subUsers.length > 0) {
            newUser.subUsers = subUsers;
            // self.sortUsers(subUsers, newUser._id);
          }
        });

        this.sortedUsers = newUsers;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.full-width {
  position: relative;
  height: 100%;
}
.open-ind,
.closed-ind {
  position: absolute;
  right: 15px;
  top: 5px;
  color: gray;
}
.name-role {
  min-height: 66px;
}
card-title {
  color: var(--v-accent_text-lighten1);
}
.sub-title {
  color: var(--v-accent_text-lighten1);
  font-size: 12px;
}
.c-card:hover {
  cursor: pointer;
}
.c-card {
  position: relative;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 22px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 180px;
  width: 300px;
  .line-bot {
    position: absolute;
    bottom: -40px;
    width: 1px;
    height: 40px;
    background-color: grey;
    margin: auto;
    left: 0;
    right: 0;
  }
  &.active {
    //   .add-btn {
    //       display: block;
    //   }
    border: 1px solid red;
    .show-more {
      display: none;
    }
    // .line-bot {
    //   position: absolute;
    //   bottom: -15px;
    //   width: 1px;
    //   height: 15px;
    //   background-color: grey;
    //   margin: auto;
    //   left: 0;
    //   right: 0;
    // }
    .line-bot {
      bottom: -60px;
      height: 60px;
    }
  }
}
.card-wrapper {
  margin-top: 12px;
  margin-bottom: 65px;
}
.sub-card-wrapper {
  position: absolute;
}
// .card-inner {
// }
// .border-top{
//     border-top:1px black solid;
// }
// .border-top:first-child::before {
// 	content: '';
// 	position: absolute;
// 	top: -8px;
// 	right: 0;
// 	width: 50%;
// 	border-bottom: 1px solid grey;
// }
// .border-top:last-child::before {
// 	content: '';
// 	position: absolute;
// 	top: -8px;
// 	left: 0;
// 	width: 50%;
// 	border-bottom: 1px solid grey;
// }
.border-top-right::before {
  content: "";
  position: absolute;
  top: -8px;
  right: -10%;
  width: 60%;
  border-bottom: 1px solid grey;
}
.border-top-left::before {
  content: "";
  position: absolute;
  top: -8px;
  left: -10%;
  width: 60%;
  border-bottom: 1px solid grey;
}
.border-top::before {
  content: "";
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  width: 100%;
  border-bottom: 1px solid grey;
}

.col-card {
  position: relative;
}
.line-top {
  position: absolute;
  top: -20px;
  width: 1px;
  height: 20px;
  background-color: grey;
  margin: auto;
  left: 0;
  right: 0;
}
.c-card:hover .add-btn {
  display: block;
}
.show-more {
  position: absolute;
  right: 44%;
  bottom: -50px;
  i {
    font-size: 18px;
  }
  i:hover {
    font-size: 22px;
  }
}
.add-btn:hover {
  cursor: pointer;
}
.add-btn {
  display: none;
  background-color: #84807c;
  border-radius: 50px;
  height: 25px;
  width: 25px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  line-height: 25px;

  //   padding: 0 0 10px 0;
}
.add-top {
  position: absolute;
  right: 46%;
  top: -12px;
}
.add-right {
  position: absolute;
  right: -13px;
  top: 42.5%;
}
.add-bottom {
  position: absolute;
  right: 46%;
  bottom: -13px;
}
.add-left {
  position: absolute;
  left: -13px;
  top: 42.5%;
}
</style>
