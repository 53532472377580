<template>
  <div>
    <v-alert v-if="getAccountError" class="font-weight-bold red--text text-h7 mt-2">
      <div>Get Account Error!</div>
      <div>{{ getAccountError }}</div>
    </v-alert>
    <v-row v-if="userAccount && userAccount.paymentPlan">
      <v-col cols="12" md="6">
        <div style="font-size: 16px" class="mb-4 font-weight-bold">Your Current Plan</div>
        <div class="current-plan pa-6" v-if="!userAccount.paymentPlanId">
          <div class="d-flex">
            <div class="mr-3">
              <img height="20px" src="/img/question-bullet-actve.png" />
            </div>
            <div>
              <div class="pt-2">
                <strong>No Plan</strong>
              </div>
              <div style="font-size: 14px">Select a plan below</div>
            </div>
          </div>
        </div>

        <div class="current-plan pa-6" v-if="userAccount && userAccount.paymentPlanId">
          <div class="d-flex">
            <div class="mr-3">
              <img class="mt-5" v-if="userAccount.paymentPlanId.indexOf('gap_plus') == -1" height=" 20px"
                src="/img/question-bullet-actve.png" />
              <img class="mt-2" v-if="userAccount.paymentPlanId.indexOf('gap_plus') > -1" height="40px"
                src="/img/gap_plus_logo_solo.png" />
            </div>
            <div>
              <div class="pt-2">
                <strong>{{ userAccount.paymentPlan.name }}</strong>
              </div>
              <div style="font-size: 14px" v-if="userAccount.paymentPlanId.indexOf('gap_plus') > -1">
                GAP+ SUBSCRIPTION
              </div>
              <div style="font-size: 14px" v-if="userAccount.paymentPlanId.indexOf('gap_plus') == -1">
                GAP SELLING ONLINE TRAINING
              </div>
            </div>
          </div>
          <div>
            <div v-if="accountError === false" style="font-size: 12px" class="mt-5">
              Auto renew is {{ autoRenew ? " ON" : " OFF" }}
            </div>
            <div style="font-size: 12px" class="mt-1">
              <div v-if="accountError === true" class="account-error">
                {{ accountErrorMessage }}
              </div>

              <strong v-if="accountError === false && autoRenew"> Next
                Update {{ getNextPaymentDate(userAccount)
                }}</strong>
            </div>
            <div class="mt-6" v-if="accountError === false">
              <v-btn @click="managePlanOpen = true" color="primary" outlined> Manage Plan</v-btn>
            </div>
            <div class="mt-6" v-if="accountError === true">
              <v-btn @click="scrollTo('plans')" color="primary" outlined> Reactivate
                Plan</v-btn>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div style="font-size: 16px" class="font-weight-bold mb-4">Payment Method</div>
        <div class="billing" v-if="userAccount">
          <div class="mt-2 card-wrapper pa-2"
            v-if="userAccount && userAccount.paymentMethod && userAccount.paymentMethod.brand">
            {{ userAccount.paymentMethod.brand }} ending in {{ userAccount.paymentMethod.last4 }} -
            {{ userAccount.paymentMethod.expMonth }} /
            {{ userAccount.paymentMethod.expYear }}
          </div>

          <div class="text-left my-2">
            <v-btn :disabled="updateCard" :style="!updateCard ? 'text-decoration: underline' : ''"
              @click="updateCard = true" text small color="grey">
              edit payment method
            </v-btn>
          </div>
          <div v-if="updateCardComplete" class="update-card text-center">Updated Card!</div>
          <div v-if="updateCard" class="card-form-wrapper pa-2">
            <v-form id="payment" @submit="onSubmit">
              <div class="mt-5 ml-auto ml-md-1 mr-auto">
                <Payment ref="payment" v-model="token" @updateTokenError="tokenUpdatedError"></Payment>

                <v-alert :value="hasSignupError" class="font-weight-bold red--text text-h7 mt-2">{{
      signupError
    }}</v-alert>
              </div>
              <div class="d-flex justify-right mt-3 mb-2 text-right">
                <v-btn class="ml-auto" :disabled="isSubmitting" type="submit" color="primary">Update Card
                </v-btn>
              </div>
            </v-form>
          </div>
        </div>
      </v-col>
    </v-row>
    <div id="plans">
      <Plans v-if="userAccount" :accountErrorMessage="accountErrorMessage" :userAccount="userAccount"></Plans>
    </div>

    <v-dialog v-model="managePlanOpen" max-width="600px">
      <v-card>
        <div style="position: relative" class="text-center pa-5">
          Managed Plan
          <div class="close-btn">
            <v-btn @click="cancelManagePlan(userAccount)" icon>
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <hr />
        <div class="container px-10">
          <div class="mt-12">
            <div class="mb-2"><strong>Auto-Renew?</strong></div>
            <v-row>
              <v-col style="font-size: 14px" cols="12" md="8">
                Hate interruptions? So do we. Turn on Auto-Renew and never miss a beat (or
                masterclass).
              </v-col>
              <v-col cols="12" md="4" class="mt-0 pt-0">
                <v-switch
                  v-if="userAccount && userAccount.paymentPlanId && userAccount.paymentPlanId.indexOf('gap_plus') == -1"
                  class="mt-0 pt-0" :label="autoRenew ? 'Active' : 'Inactive'" v-model="autoRenew">
                </v-switch>

                <v-btn
                  v-if="userAccount && userAccount.paymentPlanId && userAccount.paymentPlanId.indexOf('gap_plus') > -1"
                  href="https://salesgrowth.com/gap-selling-online-training-contact/" target="_blank" color="primary"
                  outlined> Contact Sales</v-btn>
              </v-col>
            </v-row>
          </div>
          <div class="mt-12">
            <div class="mb-2"><strong>Your Goals, Your Plan: Find the Right Fit</strong></div>
            <v-row>
              <v-col style="font-size: 14px" cols="12" md="8">
                Is your team growing? Need more user seats? Want to add a couple more admins? We’ve
                got you. Let’s find you the perfect balance.
              </v-col>
              <v-col cols="12" md="4">
                <v-btn href="https://salesgrowth.com/gap-selling-online-training-contact/" target="_blank"
                  color="primary" outlined> Contact Sales</v-btn>
              </v-col>
            </v-row>
          </div>
          <div class="mt-12">
            <div class="mb-2"><strong>Cancel Subscription</strong></div>

            <v-row
              v-if="userAccount && userAccount.paymentPlanId && userAccount.paymentPlanId.indexOf('gap_plus') > -1">
              <v-col cols="12" md="8">

              </v-col>
              <v-col cols="12" md="4">
                <v-btn href="https://salesgrowth.com/gap-selling-online-training-contact/" target="_blank"
                  color="primary" outlined> Contact Sales</v-btn>
              </v-col>
            </v-row>
            <v-row
              v-if="userAccount && userAccount.paymentPlanId && userAccount.paymentPlanId.indexOf('gap_plus') == -1">
              <v-col cols="12" md="8">
                We hate to see you go but understand that sometimes things change. The door’s always
                open for your return.
              </v-col>
              <v-col cols="12" md="4">
                <v-btn @click="() => {
      autoRenew = false;
    }
      " class="px-6" color="primary" outlined :disabled="!autoRenew">
                  Cancel Plan</v-btn>
              </v-col>
            </v-row>
          </div>
          <div class="mt-12 mb-12">
            <v-row class="justify-center">
              <v-col cols="12" md="3">
                <v-btn @click="cancelManagePlan(userAccount)" class="px-6" color="primary" outlined>
                  Cancel</v-btn>
              </v-col>
              <v-col cols="12" md="3">
                <v-btn @click="saveManagePlan(userAccount)" class="px-6" color="primary">
                  Save</v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Payment from "@/components/Payment";

import GET_ACCOUNT from "../graphql/GetAccount.gql";
import UPDATE_PAYMENT_METHOD from "../graphql/UpdatePaymentMethod.gql";
import UPDATE_SUBSCRIPTION from "../graphql/UpdateSubscription.gql";
import Plans from "@/components/Plans";
import GET_ACTIVE_CHECK from "../graphql/GetActiveCheck.gql";
import { DateTime } from "luxon";

export default {
  components: { Payment, Plans },

  name: "billing",
  computed: mapState(["auth"]),
  data() {
    return {
      token: null,
      userAccount: null,
      managePlanOpen: false,
      updateCard: false,
      signupError: null,
      hasSignupError: false,
      isSubmitting: false,
      updateCardComplete: false,
      autoRenew: null,
      getAccountError: null,
      accountError: null,
      accountErrorMessage: null,
    };
  },
  watch: {
    // auth(authUser) {
    //   this.getAccount(authUser.account._id);

    // },
    "auth.authUser": {
      handler: function (authUser) {
        if (authUser?.account?._id) {
          this.getAccount(authUser.account._id);
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.auth && this.auth.authUser && this.auth.authUser.account) {
      this.getAccount(this.auth.authUser.account._id);
    }
    this.checkPaymentPlan();
  },
  methods: {
    scrollTo(id) {
      const el = document.getElementById(id);
      el.scrollIntoView({ behavior: "smooth" });
    },
    getNextPaymentDate(userAccount) {
      if (userAccount && userAccount.billingCycleStart) {
        let nextPaymentDate = userAccount.billingCycleStart.split("-")
        const year = DateTime.now().year;
        const month = DateTime.now().plus({ "month": "1" }).month;
        const day = nextPaymentDate[2];

        return `${month}/${day}/${year}`;
      }
      return "";
    },
    async checkPaymentPlan() {
      const self = this;
      if (!this.auth || (this.auth && !this.auth.authUser)) {
        router.push("sign-in");
        return;
      }
      const getActiveCheckResult = await this.$apollo
        .query({
          query: GET_ACTIVE_CHECK,
          fetchPolicy: "no-cache",
        })
        .catch((error) => {
          alert("There is an issue with your account. Please update your information.");

          self.accountError = true;
          self.accountErrorMessage =
            "There is an issue with your account. Please update your information.";

          return;
        });

      if (!getActiveCheckResult.data.activeCheck.success) {
        self.accountError = true;
        self.accountErrorMessage = getActiveCheckResult.data.activeCheck.message;
      } else {
        self.accountError = false;
        self.accountErrorMessage = "";
      }
    },
    tokenUpdatedError() {
      this.isSubmitting = false;
    },
    async getAccount(accountId) {
      const getAccountResult = await this.$apollo
        .query({
          query: GET_ACCOUNT,
          fetchPolicy: "no-cache",
          variables: {
            input: { _id: accountId },
          },
        })
        .catch((error) => {
          this.getAccountError = error.message
            ? error.message.replace("GraphQL error: ", "")
            : "error.";
        });
      this.userAccount = {};
      this.userAccount = getAccountResult.data.account[0];
      this.autoRenew = !this.userAccount.cancel_at_period_end;

      // let authUser = this.auth.authUser;
      // authUser.account = this.userAccount;
      // this.$store.dispatch("auth/signin", {
      //   authUser: authUser,
      // });
    },
    async cancelManagePlan(userAccount) {
      this.getAccount(userAccount._id);
      this.managePlanOpen = false;
      console.log("cancelManagePlan", userAccount);
    },
    async saveManagePlan(userAccount) {
      this.updateCardComplete = false;

      const planAutoRenew = await this.$apollo.mutate({
        mutation: UPDATE_SUBSCRIPTION,
        variables: {
          input: { cancel_at_period_end: !this.autoRenew },
        },
      });
      console.log("saveManagePlan", planAutoRenew, userAccount);
      this.getAccount(userAccount._id);
      this.managePlanOpen = false;
    },
    async onSubmit(evt) {
      evt.preventDefault();

      if (this.$refs.form) {
        const isValid = this.$refs.form.validate();

        if (!isValid) {
          return;
        }
      }

      this.isSubmitting = true;
      let payResult;
      let token;
      // this.chargeCard = true;
      if (!this.token || (this.token && !this.token.plaidPayment)) {
        try {
          payResult = await this.$refs.payment.pay();
          if (payResult && payResult.token) {
            token = payResult.token;
          }
          console.log("payResult", payResult);
        } catch (error) {
          // error. Stop next
          console.log("error", error);
          return;
        }
      } else {
        token = this.token;
      }
      // let updateObj = {};
      // if (token.card) {
      //   token.card.metadata = token.card.metadata ? JSON.stringify(token.card.metadata) : null;
      //   updateObj.payment = token;
      // }

      // console.log("updateObj", updateObj);
      console.log("token", token);
      this.updatePaymentMethod(token.id);
      this.isSubmitting = false;
    },
    async updatePaymentMethod(token) {
      this.updateCardComplete = false;

      const planUpdateResult = await this.$apollo.mutate({
        mutation: UPDATE_PAYMENT_METHOD,
        variables: {
          token: token,
        },
      });
      console.log(planUpdateResult.data.updatePaymentMethod);
      if (planUpdateResult && planUpdateResult.data && planUpdateResult.data.updatePaymentMethod) {
        this.getAccount(this.auth.authUser.account._id);
      }
      this.updateCardComplete = true;
      this.updateCard = false;
    },
  },
};
</script>

<style>
.account-error {
  color: red;
}

.update-card {
  color: green;
}

.billing {
  max-width: 400px;
}

.current-plan {
  background-color: #efefef;
  max-width: 350px;
}

.card-wrapper {
  background-color: #efefef;
  font-size: 12px;
}

.card-form-wrapper {
  background-color: #efefef;
}

.close-btn {
  position: absolute;
  right: 5px;
  top: 8px;
}
</style>
