import { DateTime } from "luxon";

export default {
  formatDateToTime(date) {
    if (date) {
      const dt = DateTime.fromISO(date);

      return dt.toFormat("h:mm a");
    } else {
      return null;
    }
  },
  formatTime(time) {
    if (time) {
      const dt = DateTime.fromFormat(`01-01-2000 ${time}`, "MM-dd-yyyy hh:mm");

      return dt.toFormat("hh:mm a");
    } else {
      return null;
    }
  },
  getJSDateFromDateTime(date, time) {
    const dt = DateTime.fromFormat(`${date} ${time}`, "MM-dd-yyyy hh:mm");
    return dt.toJSDate();
  },
  getJSDateFromDate(date) {
    const dt = DateTime.fromISO(date);
    return dt.toJSDate();
  },
  getDateStringFromJsDate(date) {
    const dt = DateTime.fromISO(date);
    return dt.toFormat("yyyy-MM-dd");
  }
};
