<template>
  <div v-if="refresh">
    <div>Refresh Reminders</div>
    <!-- <v-row>
      <v-col cols="6" v-for="(refreshItem, index) in refreshItems" :key="index">
        {{ refreshItem.text }}
      </v-col>
    </v-row> -->
    <v-form ref="form" @submit="updateRefresh">
      <v-row>
        <v-col cols="6">
          <v-switch
            v-model="refresh.status"
            label="Active"
            false-value="in-active"
            true-value="active"
          ></v-switch>
        </v-col>
      </v-row>
      <h2>Days Since Training Started - 1st Completion</h2>
      <v-row>
        <v-col
          class="d-flex"
          cols="12"
          md="3"
          v-for="(refreshItem, index) in refreshItems.filter(x => x.text.indexOf('Activated') == 0)"
          :key="index"
        >
          <div>
            <v-text-field
              :rules="[v => !!v || '** required']"
              v-model="refresh[refreshItem.value]"
              :label="refreshItem.text.replace('Activated', '')"
              type="number"
            ></v-text-field>
          </div>
          <div class="pt-6">days</div>
        </v-col>
      </v-row>
      <h2>Days Since Training Completed - Keep It Fresh</h2>
      <v-row>
        <v-col
          class="d-flex"
          cols="12"
          md="3"
          v-for="(refreshItem, index) in refreshItems.filter(
            x => x.text.indexOf('Completion') == 0
          )"
          :key="index"
        >
          <div>
            <v-text-field
              :rules="[v => !!v || '** required']"
              v-model="refresh[refreshItem.value]"
              :label="refreshItem.text.replace('Completion', '')"
              type="number"
            ></v-text-field>
          </div>
          <div class="pt-6">days</div>
        </v-col>
      </v-row>
      <div class="text-center">
        <v-btn type="submit" color="primary"> Save </v-btn>
      </div>
    </v-form>
  </div>
</template>

<style lang="scss" scoped></style>
<script>
import { mapState } from "vuex";

import GET_REFRESH from "../graphql/GetRefresh.gql";
import UPDATE_REFRESH from "../graphql/UpdateRefresh.gql";
export default {
  name: "Refresh",
  components: {},
  data() {
    return {
      refresh: null,
      refreshItems: [
        { text: "Activated Welcome", value: "activatedWelcome" },
        { text: "Activated Problem Identification", value: "activatedProblemIdentification" },
        { text: "Activated Discovery Part 1", value: "activatedDiscoveryPart1" },
        { text: "Activated Discovery Part 2", value: "activatedDiscoveryPart2" },
        { text: "Activated Demo", value: "activatedDemo" },
        { text: "Activated Pipeline", value: "activatedPipeline" },
        { text: "Activated Final Self-Assessment", value: "activatedFinalSelfAssessment" },
        { text: "Completion Problem Identification", value: "completionProblemIdentification" },
        { text: "Completion Discovery Part 1", value: "completionDiscoveryPart1" },
        { text: "Completion Discovery Part 2", value: "completionDiscoveryPart2" },
        { text: "Completion Demo", value: "completionDemo" },
        { text: "Completion Pipeline", value: "completionPipeline" },
        { text: "Completion Intro To The Gap", value: "completionIntroToTheGap" }
      ]
    };
  },
  computed: mapState(["auth"]),
  mounted() {
    this.getRefresh();
  },
  methods: {
    async getRefresh() {
      const self = this;
      const refreshResult = await this.$apollo.query({
        query: GET_REFRESH,
        fetchPolicy: "no-cache",
        variables: { accountId: self.auth.authUser.account._id }
      });
      console.log(refreshResult.data.refresh);
      if (
        refreshResult &&
        refreshResult.data &&
        refreshResult.data.refresh &&
        refreshResult.data.refresh.length > 0
      ) {
        this.refresh = refreshResult.data.refresh[0];
      } else {
        this.refresh = {
          accountId: self.auth.authUser.account._id,
          completionProblemIdentification: 30,
          completionDemo: 120,
          completionDiscoveryPart1: 60,
          completionDiscoveryPart2: 90,
          completionIntroToTheGap: 180,
          completionPipeline: 150,
          activatedDemo: 35,
          activatedDiscoveryPart1: 21,
          activatedDiscoveryPart2: 28,
          activatedPipeline: 42,
          activatedProblemIdentification: 14,
          activatedWelcome: 7,
          activatedFinalSelfAssessment: 42,
          status: "in-active"
        };
      }
    },

    async updateRefresh(evt) {
      evt.preventDefault();
      const valid = this.$refs.form.validate();
      const self = this;

      let updatedRefresh = Object.assign(this.refresh);

      updatedRefresh.completionProblemIdentification = parseInt(
        updatedRefresh.completionProblemIdentification
      );
      updatedRefresh.completionDemo = parseInt(updatedRefresh.completionDemo);
      updatedRefresh.completionDiscoveryPart1 = parseInt(updatedRefresh.completionDiscoveryPart1);
      updatedRefresh.completionDiscoveryPart2 = parseInt(updatedRefresh.completionDiscoveryPart2);
      updatedRefresh.completionIntroToTheGap = parseInt(updatedRefresh.completionIntroToTheGap);
      updatedRefresh.completionPipeline = parseInt(updatedRefresh.completionPipeline);
      updatedRefresh.activatedDemo = parseInt(updatedRefresh.activatedDemo);
      updatedRefresh.activatedDiscoveryPart1 = parseInt(updatedRefresh.activatedDiscoveryPart1);
      updatedRefresh.activatedDiscoveryPart2 = parseInt(updatedRefresh.activatedDiscoveryPart2);
      updatedRefresh.activatedPipeline = parseInt(updatedRefresh.activatedPipeline);
      updatedRefresh.activatedProblemIdentification = parseInt(
        updatedRefresh.activatedProblemIdentification
      );
      updatedRefresh.activatedWelcome = parseInt(updatedRefresh.activatedWelcome);
      updatedRefresh.activatedFinalSelfAssessment = parseInt(
        updatedRefresh.activatedFinalSelfAssessment
      );

      delete updatedRefresh.__typename;
      if (valid) {
        await this.$apollo.mutate({
          mutation: UPDATE_REFRESH,
          variables: {
            accountId: self.auth.authUser.account._id,
            input: updatedRefresh
          }
        });
      }
    }
  }
};
</script>
