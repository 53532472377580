var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"full-width"},[(_vm.sortedUsers)?_c('div',{ref:"userrow",staticClass:"d-flex card-inner mx-0 px-0 justify-start"},_vm._l((_vm.sortedUsers),function(user,index){return _c('div',{key:user._id,staticClass:"col-card mx-0 pa-0"},[_c('div',{staticClass:"mx-auto card-wrapper",class:[
          _vm.teamAdminUser && _vm.sortedUsers.length > 1
            ? index == 0
              ? 'border-top-right'
              : index == _vm.sortedUsers.length - 1
              ? 'border-top-left'
              : 'border-top'
            : '' ],on:{"click":function($event){_vm.subUsersActive = user.subUsers && _vm.subUsersActive != user._id ? user._id : null}}},[_c('div',{staticClass:"c-card mx-3 pa-3",class:[_vm.subUsersActive == user._id ? 'active' : '']},[(user.reportsToId)?_c('div',{staticClass:"line-top"}):_vm._e(),(_vm.subUsersActive == user._id)?_c('div',{staticClass:"closed-ind"},[_c('i',{staticClass:"fas fa-minus"})]):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"py-0 name-role",attrs:{"cols":"9"}},[_c('div',[_vm._v(_vm._s(user.firstName)+" "+_vm._s(user.lastName))]),_c('div',{staticClass:"sub-title"},[_vm._v(_vm._s(user.roleTitle))]),_c('div',{staticClass:"sub-title"},[_vm._v(_vm._s(user.teamName))])]),_c('v-col',{staticClass:"px-0",attrs:{"cols":"3"}},[_vm._v(" "+_vm._s(user.status)+" ")])],1),_c('v-row',[_c('v-col',{staticClass:"my-2 py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"row justify-space-between"},[_c('div',[(
                      _vm.auth.authUser.isAdmin ||
                      _vm.auth.authUser.isCompanyAdmin ||
                      _vm.auth.authUser.canAddUsers
                    )?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.editUser(user)}}},[_c('i',{staticClass:"far fa-edit"})]):_vm._e()],1),(user.isTrainingUser)?_c('div',[_c('div',[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.getProgress(user._id, _vm.progressReport).sectionOrder))]),_vm._v(" / "+_vm._s(_vm.sections.length - 1)+" ")]),_c('div',{staticClass:"sub-title"},[_vm._v(" "+_vm._s(_vm.getProgress(user._id, _vm.progressReport).progressStatus)+" ")])]):_vm._e()])]),(user.isTrainingUser)?_c('v-col',{staticClass:"mt-2 py-0",attrs:{"cols":"12"}},[_c('v-progress-linear',{attrs:{"color":"primary","value":_vm.getProgress(user._id, _vm.progressReport).percentComplete}})],1):_vm._e()],1),(
              (_vm.auth.authUser.isAdmin ||
                _vm.auth.authUser.isCompanyAdmin ||
                _vm.auth.authUser.canAddUsers) &&
              !user.reportsToId &&
              _vm.usersPlan._id != '1'
            )?_c('div',{staticClass:"add-btn add-top",on:{"click":function($event){$event.stopPropagation();return _vm.add(null, user._id)}}},[_c('i',{staticClass:"fas fa-plus"})]):_vm._e(),(
              (_vm.auth.authUser.isAdmin ||
                _vm.auth.authUser.isCompanyAdmin ||
                _vm.auth.authUser.canAddUsers) &&
              _vm.usersPlan._id != '1'
            )?_c('div',{staticClass:"add-btn add-right",on:{"click":function($event){$event.stopPropagation();return _vm.add(user.reportsToId)}}},[_c('i',{staticClass:"fas fa-plus"})]):_vm._e(),(
              (_vm.auth.authUser.isAdmin ||
                _vm.auth.authUser.isCompanyAdmin ||
                _vm.auth.authUser.canAddUsers) &&
              _vm.usersPlan._id != '1'
            )?_c('div',{staticClass:"add-btn add-bottom",on:{"click":function($event){$event.stopPropagation();return _vm.add(user._id)}}},[_c('i',{staticClass:"fas fa-plus"})]):_vm._e(),(
              (_vm.auth.authUser.isAdmin ||
                _vm.auth.authUser.isCompanyAdmin ||
                _vm.auth.authUser.canAddUsers) &&
              _vm.usersPlan._id != '1'
            )?_c('div',{staticClass:"add-btn add-left",on:{"click":function($event){$event.stopPropagation();return _vm.add(user.reportsToId)}}},[_c('i',{staticClass:"fas fa-plus"})]):_vm._e(),(user.subUsers)?_c('div',{staticClass:"show-more text-center"},[_c('v-badge',{attrs:{"overlap":"","content":user.subUsers ? user.subUsers.length : '0'}},[_c('v-btn',{attrs:{"icon":""}},[_c('i',{staticClass:"fas fa-users"})])],1)],1):_vm._e(),(user.subUsers)?_c('div',{staticClass:"line-bot"}):_vm._e()],1)]),(user.subUsers && _vm.subUsersActive == user._id)?_c('div',{staticClass:"sub-card-wrapper"},[_c('UserRow',{attrs:{"usersPlan":_vm.usersPlan,"users":_vm.users,"progressReport":_vm.progressReport,"teamAdminUser":user._id},on:{"add":_vm.addSubUser}})],1):_vm._e()])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }