<template>
  <div>
    <label>Manager</label>

    <div class="mb-2" v-if="user">
      <span class="user-name">{{ user.text }}</span>
    </div>
    <v-autocomplete
      v-if="userList"
      v-model="user"
      :items="userList"
      :search-input.sync="userSearchInput"
      @change="changedInput()"
      placeholder="Select A Manager"
      prepend-icon="mdi-magnify"
      return-object
      :loading="loading"
    ></v-autocomplete>
  </div>
</template>

<style lang="scss" scoped>
.user-name {
  // font-weight: bold;
  color: black;
}
</style>
<script>
import Vue from "vue";
import GET_USER from "@/graphql/GetUser.gql";
import { mapState } from "vuex";
export default Vue.extend({
  computed: {
    ...mapState(["auth"])
  },
  props: {
    label: String,
    labelAction: String,
    role: String,
    userListValues: Array,
    editingUserId: String
  },

  data() {
    return {
      user: null,
      userList: [],
      userSearchInput: null,
      userId: null,
      getUsersTimeout: null,
      loading: false
    };
  },
  mounted() {
    this.user =
      this.userListValues && this.userListValues.length > 0 ? this.userListValues[0] : null;
  },
  watch: {
    // userListValues(newVal, oldVal) {
    //   console.log("update userList", this.userList);
    //   this.userList = newVal;
    // },
    userListValues(val) {
      this.user = val && val.length > 0 ? val[0] : null;
    },
    user(val) {
      if (val) {
        this.$emit("update:userId", val.value);
      }
    },
    userSearchInput(val) {
      this.$emit("update:userSearchInput", val);
      // console.log("valchange", val);
      const self = this;

      clearTimeout(this.getUsersTimeout);
      this.getUsersTimeout = setTimeout(function() {
        if (val) {
          // console.log("calling getUsers");
          self.getUsers(val);
        }
      }, 800);
    }
  },
  // mounted() {
  //   this.getUsers();
  // },
  methods: {
    changedInput() {
      this.$emit("change", this.user.value);
    },
    async getUsers(searchTerm) {
      if (searchTerm) {
        const self = this;
        this.loading = true;
        self.users = [];
        const usersResult = await self.$apollo.query(
          {
            query: GET_USER,
            fetchPolicy: "no-cache",
            variables: {
              searchTerm: searchTerm
            }
          },
          err => {
            console.log("err", err.errors[0].message);
            throw err.errors[0].message;
          }
        );
        // text: x.firstName + " " + x.lastName + " " + x.email,
        self.userList = usersResult.data.user
          .filter(x => !this.editingUserId || (this.editingUserId && x._id != this.editingUserId))
          .map(x => {
            return {
              text: `${x.firstName} ${x.lastName}`,
              value: x._id
            };
          });
        console.log(" self.userList", self.userList);
        this.loading = false;
      }
    }
  }
});
</script>
