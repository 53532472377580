<template>
  <div>
    <div class="scroll-box-wrapper">
      <div class="scroll-box">
        <!-- <h3 class="text-center mb-8 mt-3">User Progress</h3> -->
        <v-row class="row-titles">
          <v-col cols="3">
            <strong class="ml-10"> Name</strong>
          </v-col>
          <v-col cols="2">
            <strong> Title </strong>
          </v-col>
          <v-col cols="2">
            <strong>Email</strong>
          </v-col>
          <v-col cols="3">
            <strong>Progress</strong>
          </v-col>
          <v-col cols="1">
            <strong>Last Log In</strong>
          </v-col>
          <v-col cols="1">
            <strong>Invited Date</strong>
          </v-col>
        </v-row>
        <v-row v-for="user in progressReport" :key="user.email">
          <v-col cols="12" class="team-bar pl-12 py-2" v-if="user.showBar">
            {{ user.teamName }}</v-col
          >
          <v-col class="underline" cols="3">
            <div class="row">
              <div class="user-face mr-2"><i class="fas fa-user"></i></div>
              <div class="mt-0">{{ user.firstName }} {{ user.lastName }}</div>
            </div>
          </v-col>
          <v-col class="underline" cols="2">{{ user.roleTitle }}</v-col>
          <v-col class="underline" cols="2">{{ user.email }}</v-col>
          <v-col class="underline text-center" cols="3">
            <!-- {{user.progress?user.progress.sectionOrder:"0"}} - {{user.progress?user.progress.lessonOrder:"0"}} -->
            {{ getProgressStatus(user) }}
            <!-- :value="
                user.progress
                  ? user.progress.sectionOrder
                  : 0 - user.progress
                  ? user.progress.lessonOrder
                  : 0
              " -->

            <v-progress-linear
              :value="getProgress(user._id, progressReport).percentComplete"
            ></v-progress-linear>
            <div>{{ getProgress(user._id, progressReport).sectionOrder }} / 8</div>
          </v-col>
          <v-col cols="1">
            <div class="pb-2 pl-2">
              {{ moment(user.lastLogin).format("MM/DD/YY") }}
            </div>
            <div>
              <v-icon class="ml-7" v-if="user.reminderSent" color="green"
                >mdi-check-outline
              </v-icon>
              <v-tooltip v-if="!user.reminderSent" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="sendReminder(user)" class="mt-0 ml-2" v-bind="attrs" v-on="on">
                    <v-icon> mdi-email-outline </v-icon>
                  </v-btn>
                </template>
                <span>Send Reminder</span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col cols="1">
            <div class="mt-2">
              {{ moment(user.createdOn).format("MM/DD/YY") }}
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.row-titles {
  color: #8b8787;
  font-size: 12px;
  // border-bottom: 1px silver solid;
  // margin:20px;
}
.scroll-box-wrapper {
  overflow-y: auto;
}
.scroll-box {
  min-width: 1500px;
  max-width: 100%;
  font-size: 13px;
  padding-right: 20px;
}
.underline {
  border-bottom: 1px silver solid;
  padding: 25px;
}
.team-bar {
  background-color: var(--v-primary-base);
  color: white;
}
.chart-wrapper {
  height: 500px;
  width: 850px;

  .chart {
    .chart-inner {
      position: absolute;
      height: 150px;
      width: 80%;
      top: 10;
      left: 0;
      right: 0;
      margin: auto;
      overflow: hidden;
    }
  }
}
.user-face {
  background-color: #d1d3d5;
  border-radius: 50%;
  padding: 3px 9.5px;
  width: 32px;
  height: 32px;
  i {
    color: white;
    fill: white;
  }
}
</style>
<script>
import GET_PROGRESS_REPORT from "../../graphql/GetProgressReport.gql";
import SEND_REMINDER from "../../graphql/SendReminder.gql";
import Moment from "moment";
import { mapState } from "vuex";

export default {
  components: {},
  data() {
    return {
      progressReport: null,
      lastTeamName: null,
      moment: Moment,
      sections: [
        { lessonCount: 3 },
        { lessonCount: 10 },
        { lessonCount: 7 },
        { lessonCount: 10 },
        { lessonCount: 8 },
        { lessonCount: 10 },
        { lessonCount: 12 },
        { lessonCount: 1 },
        { lessonCount: 0 },
      ],
    };
  },
  mounted() {
    this.getProgressReport();
  },
  computed: mapState(["auth"]),
  methods: {
    async sendReminder(user) {
      user.reminderSent = true;
      this.$forceUpdate();
      // const sendReminderResult =
      await this.$apollo.mutate({
        mutation: SEND_REMINDER,
        variables: {
          userId: user._id,
        },
      });
    },
    getProgress(userId, progressReport) {
      if (progressReport) {
        const progressUser = progressReport.find((x) => x._id == userId);
        if (progressUser && progressUser.progress) {
          let userProgress = Object.assign({}, progressUser.progress);
          userProgress.percentComplete =
            (userProgress.sectionOrder / (this.sections.length - 1)) * 100;
          userProgress.progressStatus = this.getProgressStatus({
            progress: userProgress,
          });

          return userProgress;
        } else {
          let userProgress = {
            complete: false,
            percentComplete: 0,
            lessonOrder: 0,
            sectionOrder: 0,
          };
          userProgress.progressStatus = this.getProgressStatus({
            progress: userProgress,
          });
          return userProgress;
        }
      } else {
        return 0;
      }
    },
    async getProgressReport() {
      const getProgressReportResult = await this.$apollo
        .query({
          query: GET_PROGRESS_REPORT,
          fetchPolicy: "no-cache",
          variables: {
            input: {
              userId: this.auth.authUser._id,
              userReportsToId: this.auth.authUser.reportsToId,
            },
          },
        })
        .catch((error) => {
          this.getProgressReportError = error.message
            ? error.message.replace("GraphQL error: ", "")
            : "error.";
        });

      getProgressReportResult.data.progressReport.sort(function (a, b) {
        return a.teamName && b.teamName && a.teamName.toUpperCase() < b.teamName.toUpperCase()
          ? -1
          : 1;
      });

      getProgressReportResult.data.progressReport.forEach((x) => {
        x.showBar = false;
        if (this.lastTeamName != x.teamName) {
          x.showBar = true;
        }
        this.lastTeamName = x.teamName;
      });

      this.progressReport = getProgressReportResult.data.progressReport;
    },
    getProgressStatus(user) {
      return user.progress ? (user.progress.complete ? "Completed" : "In Progress") : "Not Started";
    },
  },
};
</script>
