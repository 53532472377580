<template>
  <div>
    <!-- <div v-if="yourPlan">Your Plan: {{ yourPlan.name }}</div> -->
    <div class="row pt-12 justify-center" v-if="plans">
      <v-col :md="i == 0 || i == 2 ? 4 : 8" cols="12" v-for="(plan, i) in plans" :key="plan._id">
        <div class="plan-wrapper" :class="plan.isCurrent || !plan.canUpgradeTo ? 'inactive' : ''">
          <v-row class="mx-auto">
            <v-col cols="12" class="mt-3" style="position: relative">
              <div class="team-growing px-10" v-if="plan.planId == 'po_internal_gap'">
                *Is your team growing?
              </div>

              <h5>&nbsp;</h5>
              <div class="plan-card single ml-auto mr-md-1 mr-auto active pa-5"
                :style="plan.isCurrent ? 'background-color:#EFEFEF; border:none;' : ''">
                <div class="top">
                  <div class="plan-name">
                    {{ plan.name }}
                    <span v-if="plan.productType == 'series'">
                      <span class="primary--text"> GAP </span>
                      <img width="15px" style="margin-bottom: 5px" src="/img/gap_plus_logo_solo_npbg.svg"
                        alt="plan-icon" />
                    </span>
                    <div v-if="plan.productType == 'gap'">ONLINE TRAINING</div>
                  </div>
                  <div class="current-plan pa-1 my-3" v-if="plan.isCurrent">Current Plan</div>

                  <!-- <div class="header-title">{{ plan.description }}</div> -->
                  <div v-if="!plan.isCurrent" class="d-flex text-center justify-content-center cost my-3">
                    <div class="amount">
                      {{ i == 0 ? "" : "Starting at " }} {{ toCurrency(plan.paymentAmountTotal) }}
                    </div>

                    <div class="per">/{{ plan.paymentTerm }}</div>
                    <div v-if="plan.planId == 'po_internal_gap'" style="color: #242424; font-size: 12px"
                      class="pt-1 pl-3">
                      billed annually
                    </div>

                    <div v-if="plan.planId == 'gap_plus_individual_learner'" style="color: #242424; font-size: 12px"
                      class="pt-1 pl-3">
                      annually subscription
                    </div>
                  </div>
                  <p v-if="plan.description" class="description pt-3">
                    {{ plan.description }}
                  </p>
                  <!-- {{ auth.authUser.account.paymentPlanId }}
                  {{ plan.stripePlans ? plan.stripePlans[1] : "p" }} -->
                </div>

                <div class="d-flex my-4 pt-3 seats-wrapper" v-if="plan.includedSeats && plan.includedSeats.length > 0">
                  <div class="seats mr-6" v-for="seat in plan.includedSeats" v-bind:key="seat.name">
                    <i :class="seat.icon"></i>
                    <span v-if="seat.details">{{ " " + seat.details }}</span>
                    <span v-if="seat.name" class="pl-2 mt-2">{{ seat.name }}</span>
                  </div>
                </div>

                <div class="includes">
                  <div style="font-size: 12px" class="primary--text mb-2">INCLUDES</div>
                  <div class="mr-6 mb-2" v-for="(included, pdindex) in getPlanDetails(plan.planId).includes"
                    v-bind:key="pdindex">
                    <i :class="getPlanDetails(plan.planId).includesIcons[pdindex]"></i>
                    <span class="pl-3">{{ included }}</span>
                  </div>
                </div>
                <div class="not-includes mt-6 pt-1" v-if="getPlanDetails(plan.planId).notIncludes">
                  <div class="mr-6 mb-2 mt-3" v-for="(notIncluded, pdindex) in getPlanDetails(plan.planId).notIncludes"
                    v-bind:key="pdindex">
                    <i class="primary--text fas fa-solid fa-xmark"></i>
                    <span class="pl-3">{{ notIncluded }}</span>
                  </div>
                </div>
                <div class="checkout-btn" v-if="plan.canUpgradeTo">
                  <v-btn @click="updatePlanConfirm(plan)" v-if="plan.action == 'contact_sales'"
                    color="primary mx-auto buy-now">
                    Contact Sales
                  </v-btn>
                  <v-btn @click="updatePlanConfirm(plan)" v-if="plan.action == 'online_purchase' && !plan.isCurrent"
                    color="primary mx-auto buy-now">
                    {{ yourPlan ? "Upgrade" : "Select Plan" }}
                  </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </div>
    <v-dialog max-width="600" v-model="showConfirm">
      <v-card class="pa-12">
        <h2 class="mb-3 text-center">Your new plan!</h2>
        <v-row class="mx-auto" v-if="selectedPlan">
          <v-col cols="12" class="mt-3">
            <h5>&nbsp;</h5>
            <div class="plan-card pa-3 solo single ml-auto mr-md-1 mr-auto active">
              <div class="top">
                <div class="header-title mb-2">
                  {{ selectedPlan.name }}
                </div>
                <div class="d-flex text-center justify-content-center cost">
                  <div class="amount">
                    {{ toCurrency(selectedPlan.paymentAmountTotal) }}
                  </div>

                  <div class="per">/{{ selectedPlan.paymentTerm }}</div>
                </div>
                <p class="pt-2">
                  {{ selectedPlan.description }}
                </p>
              </div>

              <div class="d-flex my-4 seats-wrapper">
                <div class="seats mr-6" v-for="seat in selectedPlan.includedSeats" v-bind:key="seat.name">
                  <i :class="seat.icon"></i>
                  <span v-if="seat.details">{{ " " + seat.details }}</span>
                  <span v-if="seat.name" class="pl-2 mt-2">{{ seat.name }}</span>
                </div>
              </div>

              <div class="includes">
                <div style="font-size: 12px" class="primary--text mb-2">INCLUDES</div>
                <div class="mr-6 mb-2" v-for="(included, pdindex) in getPlanDetails(selectedPlan.planId).includes"
                  v-bind:key="pdindex">
                  <i :class="getPlanDetails(selectedPlan.planId).includesIcons[pdindex]"></i>
                  <span class="pl-3">{{ included }}</span>
                </div>
              </div>
              <div class="not-includes mt-6 pt-1" v-if="getPlanDetails(selectedPlan.planId).notIncludes">
                <div class="mr-6 mb-2 mt-3"
                  v-for="(notIncluded, pdindex) in getPlanDetails(selectedPlan.planId).notIncludes"
                  v-bind:key="pdindex">
                  <i class="primary--text fas fa-solid fa-xmark"></i>
                  <span class="pl-3">{{ notIncluded }}</span>
                </div>
              </div>
            </div>
            <!-- coupon -->
            <div class="coupon-code mt-5 mx-auto">
              <div class="d-flex justify-content-center" id="input-group-coupon-code-wrapper"
                :class="getCodeError ? 'invalid' : ''">
                <v-text-field id="input-couponCode" v-model="couponCode" type="text" placeholder="Enter Coupon Code"
                  :error="getCodeError" @keydown.enter.native="applyCouponCode(couponCode)"></v-text-field>

                <v-btn @click="applyCouponCode(couponCode)" class="mx-auto py-5" color="primary" small
                  :disabled="!couponCode">
                  Apply
                  <br />Discount Code
                </v-btn>
              </div>
              <div class="small red--text" v-if="getCodeError">
                {{ getCodeError }}
              </div>
              <div class="small" v-if="coupon">
                Your
                <span v-if="coupon.percent_off">{{ coupon.percent_off }}%</span>
                <span v-if="coupon.amount_off">${{ Math.floor(coupon.amount_off / 100) }}</span>
                {{ coupon.name }} discount has been applied!
              </div>
            </div>
            <!-- coupon -->
            <h4 class="text-center mt-10 mb-2">
              Your payment today:
              {{ canUpgrade(selectedPlan) && !coupon ?
      toCurrency((selectedPlan.paymentAmountTotalDiscounted ? selectedPlan.paymentAmountTotalDiscounted :
        selectedPlan.paymentAmountTotal)
        - 40) :
      toCurrency(selectedPlan.paymentAmountTotalDiscounted ? selectedPlan.paymentAmountTotalDiscounted :
        selectedPlan.paymentAmountTotal)
              }}
            </h4>
            <div v-if="selectedPlan.planId == 'gap_plus_individual_learner'" style="color: #242424; font-size: 12px"
              class="text-center">
              annually subscription
            </div>
            <div class="text-center my-8" v-if="selectedPlan.canUpgradeTo">
              <v-btn class="mx-auto" v-if="selectedPlan.action == 'contact_sales'" color="primary mx-auto buy-now"
                href="https://salesgrowth.com/gap-selling-online-training-contact/" target="_blank">
                Contact Sales
              </v-btn>
              <v-btn class="mx-auto" :disabled="isLoading" x-large
                v-if="selectedPlan.action == 'online_purchase' && !selectedPlan.isCurrent"
                color="primary mx-auto buy-now" @click="updatePlan(selectedPlan)">
                Confirm Upgrade
              </v-btn>
              <v-row class="mt-6">
                <v-col cols="12">
                  <div class="text-center">
                    <v-btn text @click="showConfirm = false" color="primary">cancel</v-btn>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss" scoped>
.plan-wrapper {
  &.inactive {
    .plan-card {
      cursor: default;
    }
  }
}

.plan-card {
  position: relative;

  transition: 0.3s;
  width: 100%;
  border: 1px #707070 solid;
  height: 520px;

  &.solo {
    height: auto;
  }

  .top {
    .pill {
      padding: 10px 0;
      position: absolute;
      top: 215px;
      left: 0px;
      right: 0px;
      text-align: center;
      font-size: 16px;
      font-weight: bold;

      .buy-now {
        width: 80%;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    .header-title {
      font-size: 22px;
      text-align: center;
      text-transform: capitalize;
    }

    .money-sign {
      font-size: 18px;
      padding-top: 10px;
    }

    .amount.strike {
      font-size: 25px;
      padding-top: 10px;
      padding-right: 8px;
    }

    .amount {
      font-size: 16px;
    }

    .per {
      padding-top: 0px;
    }
  }

  .plan-name {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .description {
    font-size: 14px;
    height: 90px;
  }

  .seats-wrapper {
    font-size: 14px;
    padding-bottom: 15px;
    border-bottom: #e0e0e0 1px solid;
  }

  .includes,
  .not-includes {
    font-size: 14px;
  }

  .not-includes {
    border-top: #e0e0e0 1px solid;
  }

  .checkout-btn {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .current-plan {
    background-color: #fff;
    max-width: 98px;
    text-align: center;
    border-radius: 21px;
    border: 2px solid #c92027;
    font-size: 11px;
    color: #c92027;
  }

  // &.standard {
  //   .top {
  //     background-color: #9eacaf;
  //     .pill {
  //       color: #9eacaf;
  //     }
  //   }
  // }
}

.team-growing {
  position: absolute;
  top: 3px;
  left: 38px;
  background-color: var(--v-primary-base);
  padding-bottom: 5px;
  padding-top: 3px;
  color: #fff;
  font-size: 14px;
  border-radius: 4px 4px 0px 0px;
}
</style>
<script>
import { mapState } from "vuex";

import GET_PAYMENT_PLAN from "../graphql/GetPaymentPlan.gql";
import UPDATE_PLAN from "../graphql/UpdatePlan.gql";
import GET_COUPON from "../graphql/GetCoupon.gql";

const { DateTime } = require("luxon");
export default {
  name: "SignUp",
  components: {},
  props: {
    userAccount: Object,
    accountErrorMessage: String,
  },
  watch: {
    accountErrorMessage: function (val) {
      this.getPaymentPlans();
    }
  },
  data() {
    return {
      coupon: null,
      couponCode: null,
      couponCodeId: null,
      getCodeError: null,
      isLoading: false,
      plans: null,
      yourPlan: null,
      selectedPlan: null,
      showConfirm: false,
      planDetails: [
        {
          order: "1",
          planId: "1",
          includes: ["Gap Selling Online Training", "1 Participant", "Adaptive Learning"],
          includesIcons: [
            "primary--text fas fa-solid fa-check",
            "fa-regular fa-user",
            "fas fa-solid fa-map-location",
          ],
          notIncludes: [
            "Admins",
            "Team Reports",
            "Gap Selling Masterclass Library",
            "Access to Live Events",
          ],
        },
        {
          order: "2",
          planId: "po_internal_gap",
          includes: [
            "Gap Selling Online Training",
            "Gap Selling Masterclass Library",
            "Access to Live Events",
          ],
          includesIcons: [
            "primary--text fas fa-solid fa-check",
            "primary--text fas fa-solid fa-check",
            "primary--text fas fa-solid fa-check",
          ],
        },
        {
          order: "3",
          planId: "gap_plus_individual_learner",
          includes: [
            "Gap Selling Online Training",
            "Gap Selling Masterclass Library",
            "Access to Live Events",
          ],
          includesIcons: [
            "primary--text fas fa-solid fa-check",
            "primary--text fas fa-solid fa-check",
            "primary--text fas fa-solid fa-check",
          ],
        },
        {
          order: "4",
          planId: "po_internal",
          includes: [
            "Gap Selling Online Training",
            "6+ Participants",
            "Adaptive Learning",
            "2+ Admins",
            "Team reports",
          ],
          includesIcons: [
            "primary--text fas fa-solid fa-check",
            "fas fa-solid fa-user-group",
            "fas fa-solid fa-map-location",
            "fa-solid fa-user-tag",
            "fas fa-solid fa-clipboard-list",
          ],
          notIncludes: ["Gap Selling Masterclass Library", "Access to Live Events"],
        },
      ],
    };
  },
  computed: mapState(["auth"]),
  mounted() {
    this.getPaymentPlans();
  },
  methods: {
    async applyCouponCode(code) {
      try {
        this.coupon = null;
        this.couponCode = null;
        this.getCodeError = null;
        const getCouponResult = await this.$apollo
          .query({
            query: GET_COUPON,
            fetchPolicy: "no-cache",
            variables: {
              couponCode: code,
            },
          })
          .catch((error) => {
            this.getCodeError = error.message
              ? error.message.replace("GraphQL error: ", "")
              : "error.";
          });

        if (getCouponResult && getCouponResult.data && getCouponResult.data.coupon) {
          this.coupon = getCouponResult.data.coupon;
          this.couponCodeId = getCouponResult.data.coupon.id;
          this.couponCode = null;

          if (this.coupon) {
            if (this.coupon.percent_off) {
              this.selectedPlan.paymentAmountTotalDiscounted = Math.floor(
                this.selectedPlan.paymentAmountTotal -
                this.selectedPlan.paymentAmountTotal * (this.coupon.percent_off / 100)
              );
            }
            if (this.coupon.amount_off) {
              this.selectedPlan.paymentAmountTotalDiscounted = Math.floor(
                this.selectedPlan.paymentAmountTotal - this.coupon.amount_off / 100
              );
            }
          }
        }
      } catch (error) {
        console.log("getCoupon error", error);
        this.getCodeError = error;
      }
    },
    canUpgrade(selectedPlan) {
      return (
        this.accountErrorMessage ||
        (selectedPlan.planId == "gap_plus_individual_learner" &&
          DateTime.fromISO(this.userAccount.billingCycleStart).plus({ months: 12 }).diffNow().months <
          12)
      );
    },
    getPlanDetails(planId) {
      const detail = this.planDetails.find((x) => x.planId == planId);
      return detail ? detail : { includes: [], notIncludes: [] };
    },
    toCurrency(currencyString) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });
      return formatter.format(currencyString);
    },
    async getPaymentPlans() {
      const self = this;
      const plansResult = await this.$apollo.query({
        query: GET_PAYMENT_PLAN,
        fetchPolicy: "no-cache",
      });
      console.log(plansResult.data.paymentPlan);
      if (plansResult && plansResult.data && plansResult.data.paymentPlan) {
        this.plans = plansResult.data.paymentPlan.filter((x) => x.isSelectable);
      }

      let planId = this.userAccount.paymentPlanId;
      if (planId == 'single_year') {
        planId = '1';
      }
      const yourPlan = this.plans.find((x) => x.planId == planId);
      // console.log(this.auth.authUser.account.paymentPlanId);
      this.yourPlan = yourPlan;
      this.plans.forEach((x) => {
        // x.canUpgradeTo = false;
        if (self.accountErrorMessage && (self.accountErrorMessage.toLowerCase().includes("expired") || self.accountErrorMessage.toLowerCase().includes("canceled"))) {
          x.canUpgradeTo = true;
        } else {
          if (
            !yourPlan ||
            x.planId == "po_internal" ||
            x.planId == "po_internal_gap" ||
            (yourPlan && yourPlan.planId == "1" && x.planId != yourPlan?.planId)
          ) {
            x.canUpgradeTo = true;
          } else {
            x.canUpgradeTo = false;
          }
          if (x.planId == yourPlan?.planId) {
            x.isCurrent = true;
          }
        }
      });
      //  sel from url
      // if (this.$route.query.upgrade_to_plus) {
      //   const plan = this.plans.find((x) => x.planId == "gap_plus_individual_learner");
      //   this.updatePlanConfirm(plan);
      // }
      // end sel from url
    },
    async updatePlanConfirm(plan) {
      if (plan.canUpgradeTo) {
        if (plan.planId == "po_internal" || plan.planId == "po_internal_gap") {
          window.open(
            "https://salesgrowth.com/gap-selling-online-training-contact/",
            "_blank"
          );
        } else {

          if (!this.userAccount?.paymentMethod?.brand) {
            alert("Please update your payment method before upgrading.");
            return
          }

          this.selectedPlan = plan;
          this.showConfirm = true;
        }
      }
    },
    async updatePlan(plan) {
      const self = this;
      this.isLoading = true;
      if (!this.userAccount?.paymentMethod?.brand) {
        alert("Please update your payment method before upgrading.");
        return
      }
      if (plan.canUpgradeTo && plan.action == "online_purchase") {
        const confirm = window.confirm("Are you sure you want to upgrade to plan " + plan.name);
        if (confirm) {
          const planUpdateResult = await this.$apollo.mutate({
            mutation: UPDATE_PLAN,
            variables: {
              input: { _id: plan._id, couponCodeId: this.couponCodeId },
            },
          });
          console.log(planUpdateResult.data.updatePlan);
          if (planUpdateResult.data.updatePlan.success) {
            if (planUpdateResult && planUpdateResult.data && planUpdateResult.data.updatePlan) {
              let authUser = this.auth.authUser;
              authUser.account.paymentPlanId = planUpdateResult.data.updatePlan.id;
              authUser.account.paymentPlan = plan;
              authUser.account.selectedPlanId = plan.planId;
              // this.auth.authUser.account
              this.$store.dispatch("auth/signin", {
                authUser: authUser,
              });
              this.getPaymentPlans();
              this.selectedPlan = null;
              this.showConfirm = false;
              window.location.href = "/course-list-type";
            }
          } else {
            alert(planUpdateResult.data.updatePlan.message);
          }
        }
      }
      this.isLoading = false;
    },
  },
};
</script>
