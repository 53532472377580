<template>
  <div class="profile container">
    <div>
      <h4>Your Profile</h4>
      <v-form @submit="onSubmit">
        <v-row  v-if="auth">
          <div class="col-12 col-sm-4">
            <div class="col-12">
              <v-text-field
                label="Username"
                :disabled="true"
                v-model="auth.authUser.email"
                required
              ></v-text-field>
            </div>
            <div class="col-12">
              <v-text-field label="First Name" v-model="auth.authUser.firstName" required></v-text-field>
            </div>
            <div class="col-12">
              <v-text-field label="Last Name" v-model="auth.authUser.lastName" required></v-text-field>
            </div>
            <v-alert v-model="showSuccess" dense text type="success">Saved Profile</v-alert>

            <div class="row justify-end">
              <div class="col-12 col-sm-5 text-center">
                <v-btn type="submit" color="primary">Save Profile</v-btn>
              </div>
            </div>
          </div>
        </v-row>
      </v-form>
      <div class="pt-12 mt-12 text-center">
        <a href="/sign-in">Sign Out</a>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.add-btn {
  width: 10px;
}
</style>
<script>
import { mapState } from "vuex";
import UPDATE_ACCOUNT from "../graphql/UpdateAccount.gql";

export default {
  name: "profile",
  components: {},
  computed: mapState(["auth"]),
  data() {
    return {
      showSuccess: false
    };
  },
  mounted() {
    // this.getUsers();
    this.showSuccess = false;
  },
  methods: {
    async onSubmit(evt) {
      const self = this;
      evt.preventDefault();

      this.errors = [];
      try {
        const updateAccountResult = await this.$apollo.mutate({
          mutation: UPDATE_ACCOUNT,
          variables: {
            input: {
              users: [
                {
                  _id: this.auth.authUser._id,
                  firstName: this.auth.authUser.firstName,
                  lastName: this.auth.authUser.lastName
                }
              ]
            }
          }
        });
        this.showSuccess = true;
        setTimeout(() => {
          self.showSuccess = false;
        }, 3000);
        this.$store.dispatch("auth/updateAccount", {
          account: updateAccountResult.data.updateAccount
        });
      } catch (error) {
        this.errors.push(
          error.toString().replace("Error: GraphQL error: ", "")
        ); // Vue doesn't like non-native types as keys, hence string
      }
    }
  }
};
</script>
