<template>
  <div class="team mt-2">
    <div class="row">
      <div class="ml-5" v-if="auth.authUser.isAdmin">
        <v-autocomplete
          class="account-list"
          :full-width="true"
          :no-filter="true"
          @change="setAccount"
          :items="accountList"
          label="Accounts"
        ></v-autocomplete>
      </div>

      <div class="ml-4 pt-2" v-if="auth.authUser.isAdmin">
        <v-btn fab small color="primary" @click="editSelectedAccount(true)">
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </div>

      <div class="ml-7 pt-0">
        <v-checkbox @change="getUsers()" label="Show in-active" v-model="showInactive"></v-checkbox>
      </div>
    </div>

    <div class="plan-details mt-1 mb-5 pb-5" v-if="usersPlan && users">
      <div class="d-flex">
        <div class="mt-2">
          Your Plan :
          <strong>{{ usersPlan.name }}</strong>
        </div>

        <v-btn
          v-if="auth.authUser.isAdmin && account.selectedPlanId == 'po_internal'"
          fab
          small
          color="primary"
          class="ml-3"
          @click="editSelectedAccount()"
        >
          <v-icon> mdi-pencil </v-icon>
        </v-btn>
      </div>

      <div>
        Admin Only: {{ getUserCountUsed(usersPlan, "Admin") }} /
        {{ getUserCountInPlan(usersPlan, "Admin") }}
      </div>

      <div>
        Users: {{ getUserCountUsed(usersPlan, "Participant") }} /
        {{ getUserCountInPlan(usersPlan, "Participant") }}
      </div>
    </div>

    <!-- <v-alert
      class="alert mx-auto"
      v-if="users && users.length == 1"
      type="success"
      >welcome to gap selling training! Let's begin by inviting your team.
    </v-alert> -->

    <v-dialog v-model="showWelcome" max-width="800">
      <v-card>
        <v-card-text>
          <v-carousel
            :continuous="false"
            :cycle="false"
            :show-arrows="true"
            hide-delimiter-background
            delimiter-icon="mdi-minus"
            height="100%"
            v-model="helpSlideNum"
            @change="helpSlideUpdated()"
          >
            <v-carousel-item>
              <img class="pt-5 d-block mx-auto help-img" src="/img/help-1.png" />
            </v-carousel-item>

            <v-carousel-item>
              <img class="pt-5 d-block mx-auto help-img" src="/img/help-2.png" />
            </v-carousel-item>
          </v-carousel>
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          <div class="mx-auto pb-2">
            <v-btn :disabled="!canBegin" color="primary" @click="showWelcome = false">
              Begin
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- co admins -->

    <!-- <div class="c-card mt-10">
      <div class="d-flex justify-space-between">
        <h4 class="card-title">Company Admin</h4>
      </div>
      <div v-if="users" class="ml-2 ml-sm-5">
        <div
          class="col-12 isCompanyAdmin"
          v-for="coAdmin in users.filter(x=>x.isCompanyAdmin)"
          :key="coAdmin._id"
        >
          <div class="title">{{coAdmin.firstName}} {{coAdmin.lastName}}</div>
          <div class="subtitle-1">{{coAdmin.email}} | {{coAdmin.status}}</div>
        </div>
      </div>
    </div>-->

    <!-- new -->

    <div class="card-wrapper" v-if="usersPlan">
      <UserRow
        :usersPlan="usersPlan"
        @add="openDialog"
        :users="users"
        :progressReport="progressReport"
      ></UserRow>
    </div>

    <!-- e new -->

    <!-- end co admins -->

    <!-- <v-btn
      v-if="(auth.authUser.isAdmin || auth.authUser.isCompanyAdmin )"
      text
      small
      dark
      @click="openDialog()"
      color="primary"
    >add</v-btn>-->

    <v-dialog v-model="addDialogOpen" persistent max-width="800px">
      <v-form ref="form" @submit="addUser">
        <v-card>
          <v-card-title class="card-headline py-6">
            <div class="headline mx-auto">{{ userId ? "Update" : "Add" }} User</div>
          </v-card-title>

          <v-card-text>
            <v-container>
              <div
                v-if="userLimitExceeded"
                class="user-limit-exceeded d-flex align-center text-center"
              >
                <div class="user-limit-exceeded-body px-5 pb-12">
                  <div class="d-flex justify-end my-2">
                    <v-btn @click="userLimitExceeded = false" icon>
                      <v-icon> mdi-close</v-icon>
                    </v-btn>
                  </div>

                  <h2 class="primary--text mb-2">It looks like you are out of user seats</h2>
                  Contact support at
                  <a href="mailto:support@asalesguy.com" target="_blank">support@asalesguy.com </a>
                  to increase your limt.
                </div>
              </div>
              <v-expansion-panels multiple v-model="panels">
                <v-expansion-panel>
                  <v-expansion-panel-header> User Information </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col cols="12" md="8">
                        <v-text-field
                          name="email"
                          autocomplete="email"
                          label="Email*"
                          required
                          :rules="[(v) => !!v || 'Email is required']"
                          v-model="userEmail"
                          :disabled="
                            userId && activeEditUser && !activeEditUser.inviteToken ? true : false
                          "
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4" v-if="activeEditUser">
                        <div
                          class="text-center"
                          v-if="
                            activeEditUser.status != 'active' &&
                            activeEditUser.status != 'in-active'
                          "
                        >
                          {{ activeEditUser.status }}
                        </div>

                        <v-switch
                          v-if="
                            activeEditUser.status == 'active' ||
                            activeEditUser.status == 'in-active'
                          "
                          false-value="in-active"
                          true-value="active"
                          color="green"
                          v-model="activeEditUser.status"
                          label="Active"
                        >
                        </v-switch>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-text-field
                          name="firstName"
                          autocomplete="given-name"
                          label="first name*"
                          required
                          :rules="[(v) => !!v || 'First Name is required']"
                          v-model="userFirstName"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-text-field
                          name="lastName"
                          autocomplete="family-name"
                          label="last name*"
                          required
                          :rules="[(v) => !!v || 'Last Name is required']"
                          v-model="userLastName"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-text-field
                          label="Role Title*"
                          required
                          v-model="userRoleTitle"
                          :rules="[(v) => !!v || 'Role is required']"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-text-field
                          label="Team or Region Name*"
                          required
                          :rules="[(v) => !!v || 'Team Name is required']"
                          v-model="userTeamName"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <div class="lang-wrapper pt-2 pl-2 pb-2 mr-2">
                          <v-select
                            class="mr-3 lang-select"
                            v-model="userLang"
                            item-text="title"
                            item-value="value"
                            :items="langs"
                            label="Prefered Language"
                            outlined
                            hide-details
                            dense
                          >
                            <template v-slot:selection="{ item }">
                              <div class="row px-2 justify-space-between">
                                <div class="pt-2">{{ item.title }}</div>

                                <div class="pt-1">
                                  <v-img
                                    :src="`/icons/langs/${item.value}.png`"
                                    contain
                                    width="25px"
                                    height="25px"
                                  >
                                  </v-img>
                                </div>
                              </div>
                            </template>

                            <template v-slot:item="{ item }">
                              <div class="row px-2 justify-space-between">
                                <div>{{ item.title }}</div>

                                <div>
                                  <v-img
                                    :src="`/icons/langs/${item.value}.png`"
                                    contain
                                    width="25px"
                                    height="25px"
                                  >
                                  </v-img>
                                </div>
                              </div>
                            </template>
                          </v-select>
                        </div>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header> Settings </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col class="py-0" cols="8">
                        <div class="d-flex">
                          <div>
                            Are you enrolling this user in Gap Selling Online Training?
                            <span class="primary--text"> * </span>
                          </div>
                          <div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" small>
                                  mdi-information-outline</v-icon
                                >
                              </template>
                              <div style="max-width: 300px">
                                Gap Selling Online Training Course is the foundational training for
                                Problem-Centric selling. This course contains ~8 hours of Gap
                                Selling Training.
                              </div>
                            </v-tooltip>
                          </div>
                        </div>
                      </v-col>
                      <v-col class="py-0" cols="4">
                        <v-select
                          :disabled="
                            userId &&
                            isTrainingUser &&
                            (!activeEditUser || (activeEditUser && !activeEditUser.inviteToken))
                              ? true
                              : false
                          "
                          v-model="isTrainingUser"
                          @change="changeTrainingUser()"
                          required
                          :rules="[(v) => v != null || '*Required']"
                          :items="[
                            { text: 'Yes', value: true },
                            { text: 'No', value: false },
                          ]"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="py-0" cols="8">
                        <div class="d-flex">
                          <div>
                            Are you enrolling this user in GAP+ ?
                            <span class="primary--text"> * </span>
                          </div>
                          <div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" small>
                                  mdi-information-outline</v-icon
                                >
                              </template>
                              <div style="max-width: 300px">
                                Gap Plus is a content library of workshops and masterclasses each
                                expanding on specific topics related to Gap Selling, Sales,
                                Execution, Leadership, and more. Each month, new content is
                                uploaded.
                              </div>
                            </v-tooltip>
                          </div>
                        </div>
                      </v-col>
                      <v-col class="py-0" cols="4">
                        <v-select
                          :disabled="
                            userId &&
                            isTrainingUserGapPlus &&
                            (!activeEditUser || (activeEditUser && !activeEditUser.inviteToken))
                              ? true
                              : false
                          "
                          v-model="isTrainingUserGapPlus"
                          @change="changeTrainingUserGapPlus()"
                          required
                          :rules="[(v) => v != null || '*Required']"
                          :items="[
                            { text: 'Yes', value: true },
                            { text: 'No', value: false },
                          ]"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="8"
                        v-if="
                          auth.authUser._id != userId &&
                          (auth.authUser.isAdmin || auth.authUser.isCompanyAdmin)
                        "
                      >
                        <div class="d-flex">
                          <div>
                            Make this user a company admin <span class="primary--text"> * </span>
                          </div>
                          <div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" small>
                                  mdi-information-outline</v-icon
                                >
                              </template>
                              <div style="max-width: 300px">
                                Select "yes" if this person is authorized to purchase additional
                                seats. Select "no" if this person is not authorized to purchase new
                                seats.
                              </div>
                            </v-tooltip>
                          </div>
                        </div>
                      </v-col>
                      <v-col class="py-0" cols="4">
                        <v-select
                          v-model="isCompanyAdmin"
                          @change="changeCompanyAdmin()"
                          required
                          :rules="[(v) => v != null || '*Required']"
                          :items="[
                            { text: 'Yes', value: true },
                            { text: 'No', value: false },
                          ]"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        !isCompanyAdmin &&
                        (auth.authUser.isAdmin || auth.authUser.isCompanyAdmin) &&
                        auth.authUser.account.selectedPlanId != 'po_internal'
                      "
                    >
                      <v-col class="py-0" cols="8">
                        <div class="d-flex">
                          Can this user purchase new seats? <span class="primary--text"> * </span>
                          <div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" small>
                                  mdi-information-outline</v-icon
                                >
                              </template>
                              <div style="max-width: 300px">
                                A Company Admin will have visibility of the full organization if
                                selecting "yes." Select "no" if this user should only see his or
                                herself. If this user should also see a team or segment, answer "no"
                                and assign the user as the manager for the users they will need
                                visibly for.
                              </div>
                            </v-tooltip>
                          </div>
                        </div>
                      </v-col>
                      <v-col class="py-0" cols="4">
                        <v-select
                          v-model="canAddUsers"
                          required
                          :rules="[(v) => v != null || '*Required']"
                          :items="[
                            { text: 'Yes', value: true },
                            { text: 'No', value: false },
                          ]"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <UserSearch
                          :editingUserId="userId"
                          :userId.sync="userReportUserId"
                          :userListValues="userListValues"
                          label="reports to"
                          labelAction="Select Report"
                        ></UserSearch>

                        <!-- <v-text-field
                  label="User Id"
                  v-model="userReportUserId"
                  :rules="[v => !!v || 'User is required']"
                  required
                ></v-text-field>-->
                      </v-col>

                      <v-col cols="12" v-if="userInviteLink">
                        Invite Link: {{ userInviteLink }}
                      </v-col>

                      <!--  -->
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-container>

            <small>*indicates required field</small>

            <h3 v-if="inviteUserError" class="text-center red--text">{{ inviteUserError }}</h3>
          </v-card-text>

          <div v-if="signupError || editUserError" class="row justify-center mb-3">
            <h3 v-if="signupError" class="text-center red--text">{{ signupError }}</h3>

            <h3 v-if="editUserError" class="text-center red--text">{{ editUserError }}</h3>
          </div>

          <v-card-actions>
            <div class="row justify-center mb-3">
              <div class="mr-3">
                <v-btn color="grey lighten-1" @click="addDialogOpen = false">Cancel</v-btn>
              </div>

              <div class="ml-3">
                <v-btn color="primary" :disabled="savingUser" type="submit">Save</v-btn>
              </div>
            </div>
          </v-card-actions>

          <div
            class="text-center mt-5 pb-5"
            v-if="
              activeEditUser &&
              auth.authUser._id != userId &&
              (auth.authUser.isAdmin || auth.authUser.isCompanyAdmin)
            "
          >
            <v-btn
              color="red"
              text
              :disabled="activeEditUser.subUsers || !activeEditUser.inviteToken ? true : false"
              @click="deleteUser()"
            >
              delete user
            </v-btn>

            <div class="text-center" v-if="activeEditUser.subUsers && activeEditUser.inviteToken">
              you can't delete a user with reports
            </div>

            <div class="text-center" v-if="!activeEditUser.inviteToken">
              you can't delete a user that has started the training.
            </div>
          </div>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="editAccountDialogOpen" persistent max-width="600px">
      <v-form v-if="editAccountDialogOpen" ref="form" @submit="editAccount">
        <v-card>
          <v-card-title class="card-headline py-6">
            <div class="headline mx-auto">{{ accountEdit._id ? "Update" : "Add" }} PO Account</div>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Company Name *"
                    required
                    :rules="[(v) => !!v || 'Company Name is required']"
                    v-model="accountEdit.companyName"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    label="Plan *"
                    required
                    :rules="[(v) => !!v || 'Plan is required']"
                    v-model="accountEdit.companyPlanName"
                    item-text="title"
                    item-value="value"
                    :items="planNames"
                    outlined
                    hide-details
                    style="max-width: 300px"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    type="number"
                    label="Admin Cost Per Seat *"
                    required
                    :rules="[(v) => !!v || 'Admin Cost Per Seat is required']"
                    v-model="accountEdit.adminCostPerSeat"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    type="number"
                    label="Admin Seats Included *"
                    required
                    :rules="[(v) => !!v || 'Admin Seats are required']"
                    v-model="accountEdit.includedAdminSeats"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    type="number"
                    label="User Cost Per Seat *"
                    required
                    :rules="[(v) => !!v || 'User Cost Per Seat is required']"
                    v-model="accountEdit.userCostPerSeat"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    type="number"
                    label="User Seats Included *"
                    required
                    :rules="[(v) => !!v || 'User Seats are required']"
                    v-model="accountEdit.includedUserSeats"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    type="number"
                    label="GAP+ User Cost Per Seat *"
                    required
                    :rules="[(v) => !!v || 'User Cost Per Seat is required']"
                    v-model="accountEdit.userCostPerSeatGapPlus"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    type="number"
                    label="GAP+ User Seats Included *"
                    required
                    :rules="[(v) => !!v || 'User Seats are required']"
                    v-model="accountEdit.includedUserSeatsGapPlus"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    type="date"
                    label="Subscription Start Date *"
                    required
                    :rules="[(v) => !!v || 'User Seats are required']"
                    v-model="accountEdit.subscriptionStartDate"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    type="date"
                    label="Subscription End Date*"
                    required
                    :rules="[(v) => !!v || 'User Seats are required']"
                    v-model="accountEdit.subscriptionEndDate"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>

            <small>*indicates required field</small>

            <h3 v-if="inviteAccountError" class="text-center red--text">
              {{ inviteAccountError }}
            </h3>
          </v-card-text>

          <v-card-actions>
            <div class="row justify-center mb-3">
              <div class="mr-3">
                <v-btn color="grey lighten-1" @click="editAccountDialogOpen = false">Cancel</v-btn>
              </div>

              <div class="ml-3">
                <v-btn color="primary" :disabled="savingAccount" type="submit">Save</v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<style scoped>
.account-list {
  width: 230px;
}
.add-btn {
  width: 10px;
}
.invited-user {
  color: grey;
  font-style: italic;
}
.card-title {
  color: var(--v-accent_text-lighten1);
}
.sub-title {
  color: var(--v-accent_text-lighten1);
  font-size: 12px;
}
.c-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 22px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 200px;
  width: 250px;
}
.card-wrapper {
  overflow-x: auto;
  overflow-y: visible;
  /* min-width: 78vw; */
  min-height: 150vh;
  width: 87%;
  height: 100%;
  /* border: red 2px solid ; */
}
.card-headline {
  border-bottom: solid 1px grey;
}
.plan-details {
  border-bottom: 1px grey solid;
}
.alert {
  max-width: 800px;
}
.help-img {
  max-height: 70vh;
  max-width: 75vw;
}
.user-limit-exceeded {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
}
.user-limit-exceeded-body {
  background-color: #fff;
  max-width: 600px;
  margin: auto;
}
</style>
<style>
.v-input--radio-group__input .v-label:first-child {
  width: 500px;
}
</style>

<script>
import { mapState } from "vuex";
import uiHelpers from "@/utils/uiHelpers";

import GET_USER from "../graphql/GetUser.gql";
import INVITE_USER from "../graphql/InviteUser.gql";
import UPDATE_USER from "../graphql/UpdateUser.gql";
import UserSearch from "@/components/UserSearch.vue";
import UserRow from "@/components/UserRow.vue";
import GET_PROGRESS_REPORT from "../graphql/GetProgressReport.gql";
import GET_ACCOUNT from "../graphql/GetAccount.gql";
import CREATE_ACCOUNT from "../graphql/CreateAccount.gql";
import UPDATE_ACCOUNT from "../graphql/UpdateAccount.gql";

export default {
  name: "team",
  components: {
    UserSearch,
    UserRow,
  },
  computed: mapState(["auth"]),
  data() {
    return {
      panels: [0],
      users: null,
      addDialogOpen: false,
      isTrainingUser: null,
      isTrainingUserGapPlus: null,
      canAddUsers: null,
      userInviteLink: null,
      userFirstName: null,
      userLastName: null,
      userEmail: null,
      userRoleTitle: null,
      userTeamName: null,
      userId: null,
      userReportUserId: null,
      updateSelectedUserReport: null,
      userSelectedUserId: null,
      inviteUserError: null,
      isCompanyAdmin: null,
      teamAdminId: null,
      sortedUsers: null,
      userListValues: null,
      usersPlan: null,
      progressReport: null,
      savingUser: false,
      showWelcome: false,
      helpSlideNum: 0,
      canBegin: false,
      account: null,
      accountId: null,
      accounts: null,
      accountList: null,
      editAccountDialogOpen: false,
      savingAccount: false,
      accountEdit: {},
      inviteAccountError: null,
      userLimitExceeded: false,
      activeEditUser: false,
      signupError: null,
      editUserError: null,
      showInactive: null,
      userLang: "en",
      langs: [
        { title: "English", value: "en" },
        { title: "German", value: "de" },
      ],
      planNames: [
        { title: "Team Work", value: "team_work" },
        { title: "Small Company", value: "small_company" },
        { title: "Large Company", value: "large_company" },
      ],
    };
  },
  mounted() {
    if (this.auth.authUser.isAdmin) {
      this.getAccounts();
    }
    this.accountId = this.auth.authUser.account._id;
    this.account = this.auth.authUser.account;
    this.getUsers();

    if (this.account.selectedPlanId != "po_internal") {
      this.usersPlan = this.account.paymentPlan;
    } else {
      this.usersPlan = {
        name: "PO",
      };
    }
  },
  methods: {
    resetEditUser() {
      this.userFirstName = null;
      this.userLastName = null;
      this.userEmail = null;
      this.getUsers();
      this.addDialogOpen = false;
      this.savingUser = false;
      this.activeEditUser = null;
    },
    async deleteUser() {
      // this.userId
      const confirm = window.confirm("Are you sure you want to delete this user?");
      if (confirm) {
        this.savingUser = false;

        const updateResult = await this.$apollo
          .mutate({
            mutation: UPDATE_USER,
            variables: { _id: this.userId, input: { status: "deleted" } },
          })
          .catch((error) => {
            this.hasSignupError = true;
            this.editUserError = error.message
              ? error.message.replace("GraphQL error: ", "")
              : "Sign up error.";
            this.savingUser = false;
          });
        this.resetEditUser();
      }
    },
    editSelectedAccount(isNew) {
      this.accountEdit = isNew ? {} : this.account;

      this.editAccountDialogOpen = true;
    },
    setAccount(accountId) {
      if (accountId) {
        this.accountId = accountId;

        if (this.accountId == this.auth.authUser.account._id) {
          this.account = this.auth.authUser.account;
        } else {
          this.account = this.accounts.find((x) => x._id == this.accountId);
        }

        if (this.account.selectedPlanId == "po_internal") {
          this.usersPlan = {
            name: "PO",
          };
        } else {
          this.usersPlan = this.account.paymentPlan;
        }
        this.getUsers();
      }
    },
    changeTrainingUser() {
      const self = this;
      if (this.isTrainingUser) {
        const confirm = window.confirm("Are you sure? This cannot be turned off later.");
        if (!confirm) {
          setTimeout(() => {
            self.isTrainingUser = false;
          }, 0);
        }
      }
    },
    changeTrainingUserGapPlus() {
      const self = this;
      if (this.isTrainingUserGapPlus) {
        const confirm = window.confirm("Are you sure? This cannot be turned off later.");
        if (!confirm) {
          setTimeout(() => {
            self.isTrainingUserGapPlus = false;
          }, 0);
        }
      }
    },
    changeCompanyAdmin() {
      const self = this;
      if (this.isCompanyAdmin) {
        // const confirm = window.confirm(
        //   "Are you sure? There can only be 1 company admin."
        // );
        // if (!confirm) {
        // setTimeout(() => {
        //   self.isCompanyAdmin = false;
        // }, 0);
      }
      // }
    },
    getUserCountInPlan(usersPlan, role) {
      if (this.account.selectedPlanId == "po_internal") {
        if (role == "Participant") {
          return this.account.includedUserSeats;
        } else {
          return this.account.includedAdminSeats;
        }
      } else {
        if (role == "Participant") {
          return usersPlan.includedSeats
            .filter((x) => !x.isAdmin)
            .reduce((accumulator, current) => accumulator + parseInt(current.details), 0);
        } else {
          return usersPlan.includedSeats
            .filter((x) => x.isAdmin)
            .reduce((accumulator, current) => accumulator + parseInt(current.details), 0);
        }
      }
    },
    getUserCountUsed(usersPlan, role) {
      let count = 0;
      if (role == "Participant") {
        count = this.users.filter((x) => x.isTrainingUser).length;
      } else {
        count = this.users.filter((x) => !x.isTrainingUser).length;
      }
      return count;
    },
    openDialog(userLocal) {
      // this.reportsToId = reportsToId;
      this.addDialogOpen = true;

      let reportsToUser;

      if (userLocal._id) {
        this.activeEditUser = userLocal;
        this.isTrainingUser = userLocal.isTrainingUser ? true : false;
        this.isTrainingUserGapPlus = userLocal.isTrainingUserGapPlus ? true : false;
        this.isCompanyAdmin = userLocal.isCompanyAdmin ? true : false;
        this.canAddUsers = userLocal.canAddUsers;
        this.userInviteLink = userLocal.inviteToken
          ? "https://app.gapsellingonlinetraining.com/activate-user?inviteToken=" +
            userLocal.inviteToken
          : null;
        this.userFirstName = userLocal.firstName;
        this.userLastName = userLocal.lastName;
        this.userEmail = userLocal.email;
        this.userRoleTitle = userLocal.roleTitle;
        this.userTeamName = userLocal.teamName;
        this.userLang = userLocal.userLang;
        this.userId = userLocal._id;
        this.updateSelectedUserReport = false;
        this.userSelectedUserId = userLocal._id;
        this.userReportUserId = userLocal.reportsToId;
        if (this.userReportUserId) {
          reportsToUser = this.users.find((x) => x._id == userLocal.reportsToId);
        }
      } else {
        this.activeEditUser = { autoRenew: true };

        this.isCompanyAdmin = null;
        this.isTrainingUser = null;
        this.isTrainingUserGapPlus = null;
        this.canAddUsers = null;
        this.userInviteLink = null;
        this.userFirstName = null;
        this.userLastName = null;
        this.userEmail = null;
        this.userRoleTitle = null;
        this.userTeamName = null;
        this.userLang = "en";
        this.userId = null;
        this.updateSelectedUserReport = userLocal.updateSelectedUserReport;
        this.userSelectedUserId = userLocal.selectedUserId;
        this.userReportUserId = userLocal.reportsToId;
        if (this.userReportUserId) {
          reportsToUser = this.users.find((x) =>
            x._id == userLocal.reportsToId ? userLocal.reportsToId : userLocal.userReportUserId
          );
        }
      }
      if (reportsToUser) {
        this.userListValues = [
          {
            text: `${reportsToUser.firstName} ${reportsToUser.lastName}`,
            value: userLocal.reportsToId,
          },
        ];
      } else {
        this.userListValues = [];
      }
    },
    async addUser(evt) {
      evt.preventDefault();
      this.signupError = null;
      this.editUserError = null;
      if (this.$refs.form) {
        const isValid = this.$refs.form.validate();

        if (!isValid) {
          return;
        }
      }
      const self = this;
      this.savingUser = true;
      this.inviteUserError = false;
      if (!this.userId) {
        const usersUsed = this.getUserCountUsed(this.usersPlan, "Participant");
        const usersIncPlan = this.getUserCountInPlan(this.usersPlan, "Participant");
        if (this.userReportUserId && usersUsed >= usersIncPlan) {
          const confirm = window.confirm(
            "Are you sure? This will add 1 additional user to your plan."
          );
          if (!confirm) {
            return;
          }
        }
      }
      let editUser = {
        email: this.userEmail,
        firstName: this.userFirstName,
        lastName: this.userLastName,
        roleTitle: this.userRoleTitle,
        teamName: this.userTeamName,
        userLang: this.userLang,
        teamAdminId: this.teamAdminId,
        reportsToId: this.userReportUserId,
        isTrainingUser: this.isTrainingUser,
        isTrainingUserGapPlus: this.isTrainingUserGapPlus,
        isCompanyAdmin: this.isCompanyAdmin ? true : false,
        canAddUsers: this.canAddUsers || this.isCompanyAdmin,
        accountId: this.accountId,
        autoRenew: this.activeEditUser.autoRenew,
        status: this.activeEditUser.status,
      };

      let inviteResult;
      if (this.userId) {
        const updateResult = await this.$apollo
          .mutate({
            mutation: UPDATE_USER,
            variables: { _id: this.userId, input: editUser },
          })
          .catch((error) => {
            this.hasSignupError = true;
            this.editUserError = error.message
              ? error.message.replace("GraphQL error: ", "")
              : "Sign up error.";
            this.savingUser = false;
            return;
          });
      } else {
        //  add accoutn id
        editUser.accountId = this.accountId;
        inviteResult = await this.$apollo
          .mutate({
            mutation: INVITE_USER,
            variables: editUser,
          })
          .catch((error) => {
            this.hasSignupError = true;
            this.inviteUserError = error.message
              ? error.message.replace("GraphQL error: ", "")
              : "Sign up error.";
            this.savingUser = false;
            if (this.inviteUserError.indexOf("Seats Limit") > -1) {
              this.userLimitExceeded = true;
            }
            return;
          });

        if (!inviteResult) {
          return;
        }

        if (inviteResult.data.inviteUser) {
          self.userFirstName = null;
        }
      }
      // update current user
      // selectedUserReportId: this.userSelectedUserId,

      if (
        this.updateSelectedUserReport &&
        this.userSelectedUserId &&
        inviteResult.data.inviteUser.userId
      ) {
        const updateSeletedUserResult = await this.$apollo
          .mutate({
            mutation: UPDATE_USER,
            variables: {
              _id: this.userSelectedUserId,
              input: { reportsToId: inviteResult.data.inviteUser.userId },
            },
          })
          .catch((error) => {
            this.hasSignupError = true;
            this.editUserError = error.message
              ? error.message.replace("GraphQL error: ", "")
              : "Sign up error.";
            this.savingUser = false;
          });
      }
      // end update current user
      if (this.hasSignupError != true) {
        this.resetEditUser();
      }
    },
    async editAccount(evt) {
      evt.preventDefault();
      if (this.$refs.form) {
        const isValid = this.$refs.form.validate();

        if (!isValid) {
          return;
        }
      }
      this.accountEdit.selectedPlanId = "po_internal";
      this.accountEdit.signUpActiveStep = 3;
      // TO-DO create or save acccount
      const account = {
        companyName: this.accountEdit.companyName,
        companyPlanName: this.accountEdit.companyPlanName,
        adminCostPerSeat: parseFloat(this.accountEdit.adminCostPerSeat),
        includedAdminSeats: parseInt(this.accountEdit.includedAdminSeats),
        userCostPerSeat: parseFloat(this.accountEdit.userCostPerSeat),
        userCostPerSeatGapPlus: parseFloat(this.accountEdit.userCostPerSeatGapPlus),
        includedUserSeats: parseInt(this.accountEdit.includedUserSeats),
        includedUserSeatsGapPlus: parseInt(this.accountEdit.includedUserSeatsGapPlus),
        subscriptionStartDate: uiHelpers.getJSDateFromDate(this.accountEdit.subscriptionStartDate),
        subscriptionEndDate: uiHelpers.getJSDateFromDate(this.accountEdit.subscriptionEndDate),
      };
      if (this.accountEdit._id) {
        account._id = this.accountEdit._id;
        const signupResult = await this.$apollo
          .mutate({
            mutation: UPDATE_ACCOUNT,
            variables: { input: account },
          })
          .catch((error) => {
            this.hasSignupError = true;
            this.isSubmitting = false;
            this.signupError = error.message
              ? error.message.replace("GraphQL error: ", "")
              : "Sign up error.";
            return;
          });
      } else {
        account.signUpActiveStep = 3;
        account.selectedPlanId = "po_internal";
        const signupResult = await this.$apollo
          .mutate({
            mutation: CREATE_ACCOUNT,
            variables: { input: account },
          })
          .catch((error) => {
            this.hasSignupError = true;
            this.isSubmitting = false;
            this.signupError = error.message
              ? error.message.replace("GraphQL error: ", "")
              : "Sign up error.";
            return;
          });
      }
      this.isSubmitting = false;
      this.editAccountDialogOpen = false;
      this.getAccounts();
      //  signupResult.data.createAccountAndUser
    },
    async getUsers() {
      this.errors = [];
      let statuses = ["invited", "active"];
      if (this.showInactive) {
        statuses.push("in-active");
      }
      try {
        const userResult = await this.$apollo.mutate({
          mutation: GET_USER,
          variables: {
            account: this.accountId,
            statuses: statuses,
          },
        });
        // console.log(userResult.data.user);
        let users = userResult.data.user;
        // if (!users.find(x => x.isDirector)) {
        //   users.push({
        //     userType: "default",
        //     isDirector: true
        //   });
        // }
        // if (!users.find(x => x.isVP)) {
        //   users.push({
        //     userType: "default",
        //     isVP: true
        //   });
        // }
        // if (!users.find(x => x.isCSuite)) {
        //   users.push({
        //     userType: "default",
        //     isCSuite: true
        //   });
        // }

        // this.sortUsers(users);

        // console.log("this.sortedUsers", this.sortedUsers);
        this.users = users;
        this.showWelcome = !this.auth.authUser.isAdmin && this.users && this.users.length == 1;
        this.addDialogOpen = this.auth.authUser.isAdmin && this.users && this.users.length == 0;
        this.getProgress();
      } catch (error) {
        this.errors.push(error.toString().replace("Error: GraphQL error: ", ""));
      }
    },
    async getAccounts() {
      const getAccountResult = await this.$apollo
        .query({
          query: GET_ACCOUNT,
          fetchPolicy: "no-cache",
          variables: {
            input: { selectedPlanId: "po_internal" },
          },
        })
        .catch((error) => {
          this.getAccountError = error.message
            ? error.message.replace("GraphQL error: ", "")
            : "error.";
        });
      this.accounts = getAccountResult.data.account;

      this.accounts.forEach((account) => {
        account.subscriptionStartDate = uiHelpers.getDateStringFromJsDate(
          account.subscriptionStartDate
        );
        account.subscriptionEndDate = uiHelpers.getDateStringFromJsDate(
          account.subscriptionEndDate
        );
      });

      // this.accountList = [
      //   ...[
      //     {
      //       text: "My Company",
      //       value: this.accountId,
      //     },
      //   ],
      //   ...this.accounts.map((a) => {
      //     console.log("a.paymentPlanId", a.paymentPlanId);

      //     return {
      //       text: a.companyName ? a.companyName : a.email,
      //       value: a._id,
      //     };
      //   }),
      // ];

      this.accounts.sort((a, b) => (a.companyName > b.companyName ? 1 : -1));
      const accountsLookup = [
        ...[
          {
            text: "My Company",
            value: this.accountId,
          },
        ],
        ...this.accounts.map((a) => {
          console.log("a.companyName", a.companyName);

          return {
            text: a.companyName ? a.companyName : a.email,
            value: a._id,
          };
        }),
      ];
      this.accountList = accountsLookup;
    },
    async getProgress() {
      let vars;
      if (this.accountId) {
        vars = {
          accountId: this.accountId,
        };
      }

      const getProgressReportResult = await this.$apollo
        .query({
          query: GET_PROGRESS_REPORT,
          fetchPolicy: "no-cache",
          variables: { input: vars },
        })
        .catch((error) => {
          this.getProgressReportError = error.message
            ? error.message.replace("GraphQL error: ", "")
            : "error.";
        });
      // debugger;
      this.progressReport = getProgressReportResult.data.progressReport;
    },
    getProgressStatus(user) {
      return user.progress ? (user.progress.complete ? "Completed" : "In Progress") : "not started";
    },
    helpSlideUpdated() {
      this.canBegin = true;
    },
  },
};
</script>
